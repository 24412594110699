import * as CONST from '../utils/constants';
import Util from '../utils/ui';

class Layout {
    constructor() {
        this.menuToggle = $('.mobile-menu-toggle');
        this.nav = $('.nav');
    }

    init() {
        this.bindEvents();
    }

    bindEvents() {
        this.menuToggle.on('click', e => { this.toggleMenu(e); });
        this.nav.on('click', e => { e.stopPropagation(); });

        $(window).on('click', e => {
            this.nav.removeClass('open');
        });

        $(window).on('resize', e => this.onResize(e));
    }

    onResize(e) {
        
    }

    toggleMenu(e) {
        e.preventDefault();
        e.stopPropagation();

        this.nav.toggleClass('open');
    }
}

export default Layout;