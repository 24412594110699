import $ from 'jquery';
import 'jquery-mask-plugin';
import 'jquery-validation';
import 'jquery-validation-unobtrusive';
import 'modaal/dist/js/modaal.min.js';
import 'slick-carousel';

class Common {
    constructor() {
        this.tab = $('.js-tabs button');
        this.footerSuggestedDocument = $('.js-toggle-document-url');
    }

    init() {
        this.bindEvents();
        this.initSlider();
        this.initJumpMenu();

        if ($('#contactMap').length) {
            this.initContactMap();
        }

        $('.apply_now').modaal();
        $('.inline').modaal();

        $('.js-splash-open').modaal({
            start_open: true,
            custom_class: 'splash'
        });

        $('.js-external-link').modaal({
            type: 'confirm',
            confirm_button_text: 'Continue',
            confirm_cancel_button_text: 'Cancel',
            confirm_title: 'Continue to External Link',
            confirm_content: '<p>You are leaving the Credit Union site to a external link that is associated with LHFS. Do you wish to continue?</p>',
            confirm_callback: function (e) {
                let url = (e.attr('href'));
                window.location.href = url;
            }
        });

        $('form').validate();

        // input masks
        $('.js-phone-input').mask('(000) 000-0000');
        $('.js-ssn-input').mask('000-00-0000');
        $('.js-money-input').mask('#,##0', {reverse: true});

        if (window.location.href.indexOf('?tab=') > 0) {
            let tab = this.getUrlParameter('tab');

            if (tab === 'refinance' || tab === 'reverse-mortgage') {
                $('.tab-content-item:not(' + '#' + tab + ')').slideUp();
                $('#' + tab).slideToggle();

                this.tab.removeClass('active');
                $('.js-tabs').find("[data-toggle='#" + tab + "']").addClass('active');
            }
        }

        $('#hecm-application-form').on('submit', () => {
            $('.js-money-input').each(function () {
                let fieldValue = $(this).val();
                fieldValue = fieldValue.replace(",", "");
                $(this).val(fieldValue);
            })
        });

        $('.accordion').click(function () {
            $(this).toggleClass("active");
            $(this).next(".accordion-content").slideToggle();
        });
    }

    bindEvents() {
        this.tab.on('click', e => { this.toggleTab(e); });
        this.footerSuggestedDocument.on('click', e => { this.queryStringDocument(e); });

        $('#contact-form').on('submit', e => { this.validateForm(e); });
    }

    getUrlParameter(sParam) {
        let sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
    };

    initJumpMenu() {
        let jumpMenu = $('#jump-menu');
        let jumpLinks = $('#jump-links');

        $('#jump-menu button:not(".dropdown-toggle")').click(() => {
            jumpLinks.toggleClass('collapse');
            jumpMenu.toggleClass('open');
        })

        $('#jump-links .jump-link').click((e) => {
            jumpLinks.addClass('collapse');
            jumpMenu.removeClass('open');
        })


        //if more than 3 jump links, have overflow dropdown on medium size screens
        if ($('#jump-links .jump-link').length > 3) {
            //add dropdown toggle for extra jump-links
            jumpMenu.append('<button class="btn dropdown-toggle">More</button>');

            //get jump links and copy to overflow dropdown
            let jumpLinksDropdown = $('<div id="jump-links-dropdown" class="jump-links collapse" />').append($('#jump-links').clone().html());

            jumpMenu.append(jumpLinksDropdown);

            $('#jump-menu .dropdown-toggle').click(() => {
                $('#jump-links-dropdown').toggleClass('collapse');
                $(this).toggleClass('open');
            })

            $('#jump-links-dropdown .jump-link').click((e) => {
                e.preventDefault();
                $('#jump-links-dropdown').addClass('collapse');
                $('#jump-menu .dropdown-toggle').removeClass('open');
                let href = e.target.getAttribute('href');
                document.querySelector(href).scrollIntoView({
                    behavior: 'smooth'
                })
            })
        }
    }

    initSlider() {
        $('.js-gallery-slider').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            accessibility: true,
            autoplay: false,
            dots: false,
            arrows: true,
            useTransform: true,
            variableWidth: false,
            adaptiveHeight: false,
        });

        $('.js-testimonial-slider').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            accessibility: true,
            autoplaySpeed: 4000,
            autoplay: true,
            dots: false,
            arrows: false,
            useTransform: true,
            variableWidth: false,
            adaptiveHeight: false,
        });
    }

    initContactMap() {
        const latitude = parseFloat(document.getElementById('contactMap').getAttribute('lat'));
        const longitude = parseFloat(document.getElementById('contactMap').getAttribute('lng'));

        var script = document.createElement('script');
        script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyB5HlnqQ6Wcec1f5HkQ51AfA0osDeAvP_M&callback=initMap';
        script.async = true;

        // Attach your callback function to the `window` object
        window.initMap = function () {
            // JS API is loaded and available
            let map = new google.maps.Map(document.getElementById("contactMap"), {
                center: { lat: latitude, lng: longitude },
                zoom: 15,
            });

            new google.maps.Marker({
                position: { lat: latitude, lng: longitude },
                map,
                title: ''
            });
        };

        // Append the 'script' element to 'head'
        document.head.appendChild(script);
    }

    queryStringDocument(e) {
        e.preventDefault();
        let target = e.currentTarget;
        let currentPath = window.location.pathname.replace('/', '');
        let href = $(target).attr('href');

        if (currentPath === 'refinance' || currentPath === 'reverse-mortgage') {
            window.location.href = window.location.protocol + '//' + window.location.host + href + '?tab=' + currentPath;
        }
        else {
            window.location.href = window.location.protocol + '//' + window.location.host + href;
        }
    }

    toggleTab(e) {
        let target = e.currentTarget;
        let toggle = $(target).data('toggle');

        if (!$(target).hasClass('active')) {
            $('.tab-content-item:not(' + toggle + ')').slideUp();
            $(toggle).slideToggle();

            this.tab.removeClass('active');
            $(target).addClass('active');
        }
    }

    validateForm(e) {
        if (grecaptcha.getResponse().length === 0) {
            e.preventDefault();
            $('#recaptcha_contact').next().show();
            return false;
        }
        else {
            $('#recaptcha_contact').next().hide();
            return true;
        }
    }
}

export default Common;