import $ from 'jquery';

global.$ = global.jQuery = window.$ = window.jQuery = $;

// load npm plugins
import 'babel-polyfill';

// load components
import Common from './components/common';
import Layout from './components/layout';
import ReverseMortgageCalculator from './components/reverseMortgageCalculator';

$().ready(e => {
	let common = new Common();
    common.init();

    let layout = new Layout();
    layout.init();

    let reverseMortgageCalculator = new ReverseMortgageCalculator();
    reverseMortgageCalculator.init();
});
